html,
body {
  width: 100%;
  height: 100%;
}

iframe {
  border: none;
}

.dashboard-embed-container {
  width: 100%;
  height: 100%;
  display: flex;
}

.report-visual-embed-container {
  width: 100%;
  height: 100%;
  display: flex;
}

.report-library-embed-container {
  width: 100%;
  height: 100%;
  display: flex;
}

.agg-cell-role .ag-cell-wrapper {
  justify-content: center;
}

.agg-cell-role .ag-cell-wrapper .ag-checkbox-input-wrapper::after {
  color: rgba(0, 0, 0, 0.6) !important;
}

.grid-row-highlighted {
  font-weight: 600;
}

